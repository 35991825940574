import {Tag, Typography} from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import {RiCloseFill} from 'react-icons/ri';

const AppTag = ({
  avatar,
  icon,
  label,
  className,
  color,
  shape,
  clickable,
  visible,
  onClose,
  children,
}) => {
  return (
    <Tag
      className={clsx(className, `ant-tag-${shape}`, {
        'cursor-pointer': clickable,
      })}
      visible={visible}
      icon={icon}
      color={color}
      closable={!!onClose}
      closeIcon={<RiCloseFill fontSize={14} />}>
      {avatar}
      <Typography.Text className='d-flex align-items-center'>
        {children || label}
      </Typography.Text>
    </Tag>
  );
};

AppTag.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  clickable: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  icon: PropTypes.element,
  avatar: PropTypes.element,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  shape: PropTypes.oneOf(['round', 'circle', 'square']),
};

AppTag.defaultProps = {
  color: 'default',
  shape: 'round',
  visible: true,
};

export default React.memo(AppTag);
