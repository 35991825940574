import AppTable from '@wieldy/components/AppTable';
import {Button, Empty, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useUpdateCandidate} from 'app/services/candidateService';
import {useUpdateInquiry} from 'app/services/inquiryService';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {
  CANDIDATE_REJECT_REASON,
  CANDIDATE_STATUSES,
} from '../../../../../../constants/candidate-statuses';
import ApproveRejectActions from '../ApproveRejectActions';
import ChangeCandidateModal from '../ApproveRejectActions/ChangeCandidateModal';
import ConfirmCandidateModal from '../ApproveRejectActions/ConfirmCandidateModal';
import WithdrawCandidateActions from '../ApproveRejectActions/WithdrawCandidateActions';
import {useGetColumns} from './columns';

const CandidatesTable = ({
  candidates = [],
  inquiryStatus,
  isShowNoteButton,
  handleShowEdit,
  requisition,
}) => {
  const navigate = useNavigate();
  const {id} = useParams();

  const isClosed = ['closed', 'engaged', 'withdrawn', 'declined'].includes(
    inquiryStatus,
  );

  const [candidateId, setCandidateId] = useState(null);
  const selectedCandidate = candidates.find(({id}) => candidateId === id);

  const {mutate} = useUpdateInquiry(id);
  const {mutate: mutateCandidate} = useUpdateCandidate('inquiry', id);
  const {isClient} = useAccountType();

  const handleAdd = useCallback(() => {
    navigate(`/inquiries/edit-inquiry/${id}/${inquiryStatus}`);
  }, [id, inquiryStatus, navigate]);

  const handleReject = useCallback(
    (status, reason) => {
      const key_reason = `${status}_reason`;
      mutate(
        {
          status,
          [key_reason]: reason,
        },
        {},
      );
    },
    [mutate],
  );

  const [isOpenInquiry, setIsOpenInquiry] = useState(false);

  const handleCloseInquiryPopup = useCallback((bool) => {
    setIsOpenInquiry(bool);
  }, []);

  //MODALS STATES
  const [isOpenModal, setIsOpenModal] = useState(null);
  const onOpenModal = useCallback((id, type) => {
    return () => {
      setIsOpenModal(type);
      setCandidateId(id);
    };
  }, []);

  const onSubmitChangeStatus = (candidate) => {
    const payload = {
      id: candidateId,
      candidate,
    };

    mutateCandidate(payload, {
      onSuccess: () => {
        setCandidateId(null);
        setIsOpenModal(null);
      },
    });
  };

  const onSubmitWithdrawCandidate = (formData) => {
    const payload = {
      id: candidateId,
      candidate: {status: CANDIDATE_STATUSES.REJECTED, ...formData},
    };

    mutateCandidate(payload, {
      onSuccess: () => {
        setCandidateId(null);
        setIsOpenModal(null);
      },
    });
  };
  const onSubmitConfirmCandidate = (formData) => {
    const {is_available, ...candidate} = formData;

    let payload = {
      id: candidateId,
    };

    if (is_available) {
      payload.candidate = candidate;
    } else {
      payload.candidate = {
        status: CANDIDATE_STATUSES.REJECTED,
        reject_reason: CANDIDATE_REJECT_REASON.NO_LONGER_AVAILABLE,
      };
    }

    mutateCandidate(payload, {
      onSuccess: () => {
        setCandidateId(null);
        setIsOpenModal(null);
      },
    });
  };

  const columns = useGetColumns({
    onOpenModal,
  });

  return (
    <div className='relative'>
      <Typography.Title level={5} className={'mb-5'}>
        Candidates
      </Typography.Title>
      <AppTable
        columns={columns}
        dataSource={candidates}
        pagination={false}
        emptyText={<Empty description='No Candidates' />}
      />
      <ChangeCandidateModal
        isOpenModal={isOpenModal === 'status'}
        handleClose={() => setIsOpenModal(null)}
        handleSuccess={onSubmitChangeStatus}
        isContract={requisition.isContract}
        selectedCandidate={selectedCandidate}
      />
      <WithdrawCandidateActions
        isOpenModal={isOpenModal === 'withdraw'}
        handleClose={() => setIsOpenModal(null)}
        handleSuccess={onSubmitWithdrawCandidate}
      />
      <ConfirmCandidateModal
        isOpenModal={isOpenModal === 'confirm'}
        onClose={() => setIsOpenModal(null)}
        onSubmit={onSubmitConfirmCandidate}
        candidate={selectedCandidate}
      />
      <ApproveRejectActions
        onReject={handleReject}
        status={inquiryStatus}
        handleAdd={handleAdd}
        isVisibleModal={isOpenInquiry}
        handleVisibleModal={handleCloseInquiryPopup}
      />
      {isShowNoteButton && isClient && !isClosed && (
        <Button
          type='primary'
          ghost
          className='note-button absolute bottom-0 right-0'
          onClick={handleShowEdit}>
          Add Note to Supplier
        </Button>
      )}
    </div>
  );
};

CandidatesTable.propTypes = {
  candidates: PropTypes.array,
  inquiryStatus: PropTypes.string,
  isShowNoteButton: PropTypes.bool,
  handleShowEdit: PropTypes.func,
  requisition: PropTypes.object,
};
export default CandidatesTable;
