import AppCard from '@wieldy/components/AppCard';
import AppGridContainer from '@wieldy/components/AppGridContainer';
import {Col} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {ClientCard} from 'app/shared/client';
import Notes from 'app/shared/components/Notes/Notes';
import RequisitionCard from 'app/shared/requisitions/RequisitionCard';
import {SupplierCard} from 'app/shared/supplier';
import PropTypes from 'prop-types';
import React from 'react';

import EditNote from '../../CreateInquiry/MainContent/AccompanyingMessage/EditNote';
import AboutInquiry from './AboutInquiry';
import CandidatesTable from './CandidatesTable';

const MainContent = ({
  inquiry,
  requisition,
  isShowNotes,
  notesTotal,
  notesResponse,
  handleEditNote,
  handleShowEdit,
  isShowNote,
}) => {
  const {isSupplier} = useAccountType();

  return (
    <AppGridContainer>
      <Col xs={24} lg={15} xxl={16}>
        <AppGridContainer>
          <Col sm={24}>
            <AppCard>
              <AboutInquiry inquiry={inquiry} />
              <EditNote
                isShowForm={isShowNote}
                status={inquiry.status_key}
                handleEditNote={handleEditNote}
                handleCancel={handleShowEdit}
                message={inquiry?.accompanying_message}
                title={isSupplier ? 'Note from Client' : 'Note to Supplier'}
              />
              <CandidatesTable
                handleShowEdit={handleShowEdit}
                isShowNoteButton={!inquiry?.accompanying_message}
                handleEdit={handleEditNote}
                candidates={inquiry?.candidates}
                inquiryStatus={inquiry.status_key}
                requisition={requisition}
              />
            </AppCard>
          </Col>
          {isShowNotes && (
            <Col sm={24} id='notes'>
              <Notes
                entity={'inquiry_notes'}
                primaryKey={'inquiry'}
                primaryId={inquiry.id}
                notesResponse={notesResponse}
                notesTotal={notesTotal}
              />
            </Col>
          )}
        </AppGridContainer>
      </Col>
      <Col xs={24} lg={9} xxl={8}>
        <AppGridContainer>
          <Col xs={24} sm={24} md={12} lg={24} id='client'>
            {isSupplier ? (
              <ClientCard client={inquiry.client} />
            ) : (
              <SupplierCard supplier={inquiry.supplier} />
            )}
          </Col>
          <Col xs={24} sm={24} md={12} lg={24} id='requisition'>
            <RequisitionCard requisition={requisition} />
          </Col>
        </AppGridContainer>
      </Col>
    </AppGridContainer>
  );
};

MainContent.propTypes = {
  inquiry: PropTypes.object,
  requisition: PropTypes.object,
  isShowNotes: PropTypes.bool,
  notesResponse: PropTypes.object,
  notesTotal: PropTypes.number,
  handleEditNote: PropTypes.func,
  handleShowEdit: PropTypes.func,
  isShowNote: PropTypes.bool,
};

export default MainContent;
