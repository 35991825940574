import AppTable from '@wieldy/components/AppTable';
import {Button, Empty, Form, Typography} from 'antd';
import {useAccountType} from 'app/hooks/useAccountType';
import {useStatus} from 'app/hooks/useStatus';
import {useUpdateCandidate} from 'app/services/candidateService';
import {useUpdateSubmission} from 'app/services/submissionService';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {CANDIDATE_STATUSES} from '../../../../../../constants/candidate-statuses';
import ChangeCandidateActions from '../ApproveRejectActions/ChangeCandidateActions';
import WithdrawCandidateActions from '../ApproveRejectActions/WithdrawCandidateActions';
import ApproveRejectActions from './../ApproveRejectActions';
import {useGetColumns} from './columns';

const CandidatesTable = ({
  candidates,
  status,
  isShowNoteButton,
  handleShowEdit,
  requisition,
}) => {
  const navigate = useNavigate();
  const {id} = useParams();
  const [candidateId, setCandidateId] = useState(null);
  const selectedCandidate = candidates.find(({id}) => candidateId === id);
  const isOneCandidate = candidates.length === 1;

  const {mutate} = useUpdateSubmission(id);
  const {mutate: mutateCandidate} = useUpdateCandidate('submission', id);
  const {isClient, isSupplier} = useAccountType();
  const {isResponded, isSent} = useStatus(status);
  const isShowButtons = isResponded || isSent;
  const showBulkAction = isClient || isShowButtons;

  const isClosed = ['closed', 'engaged', 'withdrawn', 'declined'].includes(
    status,
  );

  const handleAdd = useCallback(() => {
    navigate(`/submissions/edit-submission/${id}/${status}`);
  }, [id, navigate, status]);

  //CHANGE CANDIDATE LOGIC --START--
  const [isChangeStatus, setIsChangeStatus] = useState(false);

  const handleChangeCandidateStatus = useCallback((id) => {
    setIsChangeStatus(true);
    setCandidateId(id);
  }, []);

  const handleChangeStatus = (candidate) => {
    const payload = {
      id: candidateId,
      candidate,
    };

    mutateCandidate(payload, {
      onSuccess: () => {
        setCandidateId(null);
        setIsChangeStatus(false);
      },
    });
  };
  //CHANGE CANDIDATE LOGIC --END--
  //REJECT SUB --START--
  const handleReject = useCallback(
    (status, reason) => {
      const key_reason = `${status}_reason`;
      mutate({
        status,
        [key_reason]: reason,
      });
    },
    [mutate],
  );
  //REJECT SUB --END--

  //WITHDRAW SUB --START--
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handlePopup = useCallback((bool) => {
    setIsOpenModal(bool);
  }, []);
  //WITHDRAW SUB --END--

  //WITHDRAW CAND --START--
  const [isOpenWithdrawCandidate, setIsOpenWithdrawCandidate] = useState(false);

  const handleOpenWithdrawCandidate = useCallback(
    (id) => {
      if (isOneCandidate) {
        setIsOpenModal(true);
      } else {
        setIsOpenWithdrawCandidate(true);
        setCandidateId(id);
      }
    },
    [isOneCandidate],
  );

  const onSubmitWithdrawCandidate = (formData) => {
    const payload = {
      id: candidateId,
      candidate: {
        status: CANDIDATE_STATUSES.REJECTED,
        ...formData,
      },
    };

    mutateCandidate(payload, {
      onSuccess: () => {
        setCandidateId(null);
        setIsOpenModal(null);
      },
    });
  };
  //WITHDRAW CAND --END--

  const columns = useGetColumns({
    showBulkAction,
    isShowActions: showBulkAction,
    handleOpenWithdrawCandidate,
    handleChangeCandidateStatus,
    requisition,
  });

  const [form] = Form.useForm();
  const refInitValue = useRef(candidates);

  useEffect(() => {
    if (!isEmpty(refInitValue.current)) {
      form.setFieldsValue(refInitValue.current);
    }
  }, [form]);

  return (
    <div className='relative'>
      <Typography.Title level={5} className={'mb-5'}>
        Candidates
      </Typography.Title>
      <AppTable
        columns={columns}
        dataSource={candidates}
        pagination={false}
        emptyText={<Empty description='No Candidates' />}
      />
      <ChangeCandidateActions
        selectedCandidate={selectedCandidate}
        actionLabel={'Status or Stage'}
        isOpenModal={isChangeStatus}
        handleClose={() => setIsChangeStatus(false)}
        handleSuccess={handleChangeStatus}
        isContract={requisition.isContract}
      />
      <WithdrawCandidateActions
        isOpenModal={isOpenWithdrawCandidate}
        handleClose={() => setIsOpenWithdrawCandidate(false)}
        handleSuccess={onSubmitWithdrawCandidate}
      />
      <ApproveRejectActions
        form={form}
        onReject={handleReject}
        status={status}
        handleAdd={handleAdd}
        isVisibleModal={isOpenModal}
        handleVisibleModal={handlePopup}
      />
      {isShowNoteButton && isSupplier && !isClosed && (
        <Button
          type='primary'
          ghost
          className='note-button absolute bottom-0 right-0'
          onClick={handleShowEdit}>
          Add Note to Client
        </Button>
      )}
    </div>
  );
};

CandidatesTable.propTypes = {
  candidates: PropTypes.array,
  status: PropTypes.string,
  requisition: PropTypes.object,
  isShowNoteButton: PropTypes.bool,
  handleShowEdit: PropTypes.func,
};
export default CandidatesTable;
