import AppSelect from '@wieldy/components/dataEntry/AppSelect';
import {
  getRequiredMessage,
  getSelectPlaceHolder,
} from '@wieldy/utils/helpers/MessageHelper';
import {Form, Modal, Typography} from 'antd';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {RiCloseLine} from 'react-icons/ri';

import {
  CANDIDATE_REJECT_REASON_OPTIONS,
  CANDIDATE_STATUS_OPTIONS,
  getInterviewOptions,
} from '../../../../../../constants/candidate-statuses';
import {useStatus} from '../../../../../../hooks/useStatus';

const ChangeCandidateModal = ({
  isOpenModal,
  handleClose,
  handleSuccess,
  selectedCandidate,
  isContract,
}) => {
  const [form] = Form.useForm();
  const status = Form.useWatch('status', form);
  const isDisableStage = status !== 'interview';
  const {isRejected} = useStatus(status);

  useEffect(() => {
    if (selectedCandidate) {
      const {status_key, interview_stage, reject_reason} = selectedCandidate;
      form.setFieldValue('status', status_key);
      interview_stage && form.setFieldValue('interview_stage', interview_stage);
      reject_reason && form.setFieldValue('reject_reason', reject_reason);
    }
  }, [form, selectedCandidate]);

  useEffect(() => {
    if (!isOpenModal) {
      form.resetFields();
    }
  }, [form, isOpenModal]);

  return (
    <Modal
      className='submission-reject-modal'
      title='Change Candidate Status / Stage'
      open={isOpenModal}
      cancelButtonProps={{type: 'primary', htmlType: 'button', ghost: true}}
      closeIcon={<RiCloseLine fontSize={24} />}
      okText='Change'
      width={844}
      onOk={form.submit}
      onCancel={handleClose}
      destroyOnClose={true}>
      <Typography.Paragraph>
        If you would like to change the status or interview stage of this
        candidate, select the status or interview stage below and then click
        Change button.
      </Typography.Paragraph>
      <Form
        onFinish={handleSuccess}
        layout='vertical'
        form={form}
        className='modal-reason-dropdown mt-5'>
        <div className='d-flex flex-gap-2'>
          <Form.Item
            rules={[
              {
                required: true,
                message: getRequiredMessage('Candidate Status'),
              },
            ]}
            name='status'
            label='Change Candidate Status'
            style={{maxWidth: 260, width: '100%'}}>
            <AppSelect
              placeholder={getSelectPlaceHolder('status')}
              size={'middle'}
              style={{maxWidth: 260, width: '100%'}}
              options={CANDIDATE_STATUS_OPTIONS}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: !isDisableStage,
                message: getRequiredMessage('Interview Stage'),
              },
            ]}
            name='interview_stage'
            label='Change Interview Stage'
            style={{maxWidth: 260, width: '100%'}}>
            <AppSelect
              disabled={isDisableStage}
              placeholder={getSelectPlaceHolder('interview stage')}
              size={'middle'}
              style={{maxWidth: 260, width: '100%'}}
              options={getInterviewOptions(isContract)}
            />
          </Form.Item>
          {isRejected && (
            <Form.Item
              rules={[
                {
                  required: isRejected,
                  message: getRequiredMessage('Rejection Reason'),
                },
              ]}
              name='reject_reason'
              label='Rejection Reason'
              style={{maxWidth: 260, width: '100%'}}>
              <AppSelect
                placeholder={getSelectPlaceHolder('reason')}
                size={'middle'}
                options={CANDIDATE_REJECT_REASON_OPTIONS}
              />
            </Form.Item>
          )}
        </div>
      </Form>
    </Modal>
  );
};

ChangeCandidateModal.propTypes = {
  reasonFor: PropTypes.string,
  actionType: PropTypes.string,
  actionLabel: PropTypes.string,
  isOpenModal: PropTypes.bool,
  isContract: PropTypes.bool,
  handleSuccess: PropTypes.func,
  handleClose: PropTypes.func,
  status: PropTypes.string,
  selectedCandidate: PropTypes.object,
};

export default ChangeCandidateModal;
